/* Light */
@font-face {
    font-family: 'SVN-Poppins';
    src: url('./assets/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* Regular */
@font-face {
    font-family: 'SVN-Poppins';
    src: url('./assets/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('./assets/Poppins-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
/* Medium */
@font-face {
    font-family: 'SVN-Poppins';
    src: url('./assets/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('./assets/Poppins-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
/* Semi Bold */
@font-face {
    font-family: 'SVN-Poppins';
    src: url('./assets/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('./assets/Poppins-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}
/* Bold */
@font-face {
    font-family: 'SVN-Poppins';
    src: url('./assets/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SVN-Poppins';
    src: url('./assets/Poppins-BlackItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
  font-family: 'GreatVibes';
  src: url('./assets/GreatVibes-Regular.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}